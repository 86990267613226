
.certificate-item{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;
    .add-template{
        display: flex;
        justify-content: flex-end;
        margin: 20px 0;
    }
}

